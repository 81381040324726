import React from "react";
import styled from "styled-components";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { TypographyUI } from "@ui";

import { CasesOfDesktop, CasesOfMobile } from "./components";
import { useWindowSize } from "@tools/hooks";

export const SectionFourCt = () => {
  const { width } = useWindowSize();

  return (
    <SectionBoxSd>
      <TextBoxSd>
        <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $mb="40px" $tac>
          Кейсы
        </TypographyUI.H2>
      </TextBoxSd>
      {width && width > LAPTOP_MOBILE_POINTER && (
        <CasesOfDesktop />
      )}
      {width && width <= LAPTOP_MOBILE_POINTER && (
        <CasesOfMobile />
      )}
    </SectionBoxSd>
  );
};

const SectionBoxSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  align-items: center;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding-bottom: 107px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    margin-bottom: 80px;
  }
`;

const TextBoxSd = styled.div`
  max-width: 820px;
  width: 100%;
`;
