import {useSendEvent} from "@tools/hooks";

type TData = {
  subtitle?: string;
  title?: string;
  infoText?: string;
  nameImg?: string;
  link?: string;
  textLink?: string;
  onClick?: any,
}[];

export const DATA_ONE: TData = [
  {
    subtitle: "Массовые выплаты ",
    title: "Партнер ФНС",
    infoText:
      "«Бизнес» — сервис массовых выплат самозанятым. Одновременно проводите любое количество выплат исполнителям по номеру карты или реквизитам счета",
    nameImg: "Picture",
  },
  {
    subtitle: "Расчет и уплата налогов",
    title: "Интеграция с «Мой налог»",
    infoText:
      "Исполнителю не нужно заходить в приложение — наша платформа для работы с самозанятыми автоматически рассчитывает и уплачивает налог",
    nameImg: "Picture-1",
  },
  {
    subtitle: "Документооборот",
    title: "Электронный документооборот",
    infoText:
      "Начали сотрудничество с новым самозанятым? Составим договор ГПХ за вас и предоставим все закрывающие документы для бухгалтерии",
    nameImg: "Picture-2",
  },
  {
    subtitle: "Оформление персонала",
    title: "Официальная работа",
    infoText:
      "Проверим статус самозанятого перед выплатой. Если работаете с иностранцами — уведомим МВД и напомним о продлении документов",
    nameImg: "Picture-3",
    link: "/check-inn",
    textLink: "Проверить статус",
    onClick: () => {
      const {checkSWStatus} = useSendEvent();
      checkSWStatus();
    },
  },
  {
    subtitle: "Маркетплейс",
    title: "Биржа самозанятых",
    infoText:
      "Работа с самозанятыми для юр лиц стала ещё проще — вы можете найти любого необходимого специалиста на нашем маркетплейсе",
    nameImg: "Picture-4",
    link: "https://market.selfwork.ru/",
  },
];

export const DATA_TWO: TData = [
  // {
  //   title: "Настроим прием платежей и выплаты физическим лицам на вашем сайте",
  //   infoText:
  //     "У нашей инфраструктуры есть сертификация PCI DSS SAQ-D for Service Providers — мы поможем вашему бизнесу настроить прием платежей и выплаты как самозанятым, так и физическим лицам",
  //   nameImg: "Picture-5",
  // },
  // {
  //   title: "Работаем с маркетплейсами по агентской схеме",
  //   infoText:
  //     "Настроим выдачу чеков физическим лицам, если самозанятые сотрудники оказывают услуги не вам, а вашим клиентам. Например, подойдет службам доставки и такси",
  //   nameImg: "Picture-6",
  // },
  {
    title:
      "Автоматизируем работу иностранных компаний с самозанятыми из России",
    infoText:
      "У иностранных компаний возникают проблемы с выплатами самозанятым через российские банки. Разберемся в вопросе и настроим расчеты с сотрудниками из России",
    nameImg: "Picture-7",
  },
  {
    title: "Консультируем и интегрируем",
    infoText:
      "Расскажем, как стать партнером ФНС и поможем автоматизировать работу с самозанятыми с помощью API интеграции",
    nameImg: "Picture-8",
  },
];
