import React, {useEffect} from "react";
import styled, { css } from "styled-components";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { ButtonUI, InputUI, TypographyUI } from "@ui";
import {useSendEvent} from "@tools/hooks";

const { businessLead1, businessLead2, swLead } = useSendEvent();

type TProps = {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  btnDisabled: boolean;
  handlerOfSubmitForm: (e: React.FormEvent<HTMLFormElement>) => void;
  setOpenModalWindow: React.Dispatch<React.SetStateAction<boolean | null>>;
  isRequestSuccess: null | boolean;
};


export const Quiz: React.FC<{}> = ({}) => {

  useEffect(() => {
    const mainUrl = "https://xn--80aapgyievp4gwb.xn--p1ai/promo/iframe-quiz/business";
    const urlParams = window.location.search;
    const iframeUrl = mainUrl + urlParams;
    const quizFrame: any = document.getElementById('quiz');
    quizFrame.src = iframeUrl;
    quizFrame.addEventListener('load', ()=>{
      try {
        quizFrame.style.height = quizFrame.contentWindow.document.documentElement.scrollHeight + 'px';
      } catch (e) {
        quizFrame.style.height = '700px';
      }
    })
    window.addEventListener('resize', ()=>{
      try {
        quizFrame.style.height = quizFrame.contentWindow.document.documentElement.scrollHeight + 'px';
      } catch (e) {
        quizFrame.style.height = '700px';
      }
    })
  }, []);

  useEffect(() => {
    const actionQuizListener = (e) => {
      if (e.data.actionQuiz) {
        if (e.data.actionQuiz === 'select-organisation'){
          businessLead1();
        } else if (e.data.actionQuiz === 'select-selfwork') {
          swLead();
        } else if (e.data.actionQuiz === 'send-lead') {
          businessLead2();
        }
      }
    };
    window.addEventListener('message', actionQuizListener);
    return () => {
      window.removeEventListener('message', actionQuizListener);
    };
  }, []);

  return <iframe style={{width: '100%', border: 'unset', minWidth: '100vw'}} id="quiz"></iframe>
}

export const SectionFiveCt: React.FC<TProps> = ({
  name,
  setName,
  phone,
  setPhone,
  email,
  setEmail,
  btnDisabled,
  handlerOfSubmitForm,
  setOpenModalWindow,
}) => (
  <SectionBoxSd id="quizSection">

    <Quiz />

    {/*<TextBoxSd>*/}
      {/*<TypographyUI.H2*/}
      {/*  swichPoint={LAPTOP_MOBILE_POINTER}*/}
      {/*  $mt="28px"*/}
      {/*  $mb="12px"*/}
      {/*  $tac*/}
      {/*>*/}
      {/*  Начните работать эффективнее*/}
      {/*</TypographyUI.H2>*/}
      {/*<TextOfInfoSd swichPoint={LAPTOP_MOBILE_POINTER}>*/}
      {/*  Оставьте заявку на подключение к Сам.Бизнесу, чтобы начать работу с*/}
      {/*  самозанятыми*/}
      {/*</TextOfInfoSd>*/}

      {
        //TODO?: Возможно временно вырубили (SWPS-213)
        // <MarquizForm />
      }

      {
        //TODO? (PROJECT-143) Заблокирован функционал
        /* <FormBoxSd>
        <FormSd
          onSubmit={async (e) => {
            await handlerOfSubmitForm(e);
            setOpenModalWindow(true);
          }}
        >
          <InputOfNameSd
            onChange={setName}
            value={name}
            placeholder="Ваше имя"
            labelText=""
          />
          <InputOfEmailSd
            onChange={setEmail}
            value={email}
            placeholder="Ваша эл. почта"
          />
          <InputOfPhoneSd
            onChange={setPhone}
            value={phone}
            placeholder="Номер телефона"
            labelText=""
          />
          <ButtonSd type="submit" disabled={btnDisabled}>
            Начать работу
          </ButtonSd>
          <TextOfWarningSd>
            Ввод номера телефона подтверждает ваше согласие с{" "}
            <LinkUI.NewWindow href="/legal/walletterms.pdf">
              условиями передачи информации
            </LinkUI.NewWindow>
          </TextOfWarningSd>
        </FormSd>
      </FormBoxSd> */
      }
    {/*</TextBoxSd>*/}
  </SectionBoxSd>
);

const SectionBoxSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  align-items: center;
  position: relative;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 20px;
  }
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding-bottom: 20px;
  }
`;

const TextBoxSd = styled.div`
  max-width: 820px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormBoxSd = styled.div`
  width: 100%;
`;

const FormSd = styled.form`
  width: 680px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    flex-direction: column;
    width: 100%;
    & > * + * {
      margin-top: 16px;
    }
  }
`;

const InputMixin = css`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 324px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 100%;
  }
`;
const InputOfEmailSd = styled(InputUI)`
  ${InputMixin}
`;

const InputOfNameSd = styled(InputUI.FIO)`
  ${InputMixin}
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-right: 32px;
  }
`;

const InputOfPhoneSd = styled(InputUI.Phone)`
  ${InputMixin}
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-right: 32px;
    margin-top: 32px;
  }
`;

const ButtonSd = styled(ButtonUI)`
  width: 324px;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-top: 32px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 100%;
    margin-top: 32px;
  }
`;

const TextOfInfoSd = styled(TypographyUI.Body1Regular)`
  text-align: center;
  color: var(--text-secondary);
  padding-bottom: 54px;
  width: 100%;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 547px;
  }
`;

const TextOfWarningSd = styled(TypographyUI.CaptionRegular)`
  color: var(--text-placeholder);
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    /* width: 324px; */
    /* margin-right: 32px; */
    margin-top: 32px;
    width: 100%;
    text-align: center;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    order: 1;
  }
`;
