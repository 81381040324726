import React from "react";
import { Helmet } from "react-helmet";

import { Layout, useRequestToJoin } from "@components";
import {
  SectionOfMainCt,
  SectionTwoCt,
  SectionThreeCt,
  SectionFourCt,
  SectionFiveCt,
  PartnersSectionCt,
} from "@templates/business";

const BusinessPage = () => {
  const {
    name,
    setName,
    phone,
    setPhone,
    email,
    setEmail,
    btnDisabled,
    handlerOfSubmitForm,
    isRequestSuccess,
    setIsRequestSuccess,
    openModalWindow,
    setOpenModalWindow,
  } = useRequestToJoin();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Сервис для работы с самозанятыми юр лицами | Платформа для выплат самозанятым РФ | Автоматизация массовых выплат самозанятым
        </title>
        <meta
          name="description"
          content="В поиске сервиса для работы с самозанятыми и массовых выплат? Наш сайт поможет вам с этим. Расчет с самозанятыми гражданами по безналу для юридических лиц"
        />
        <link
          rel="canonical"
          href="https://xn--80aapgyievp4gwb.xn--p1ai/business"
        />
      </Helmet>

      {
        // TODO: Возможно временно вырубили (SWPS-213)
      }
      {/* <MarquizInit /> */}

      <Layout>
        <SectionOfMainCt
          name={name}
          setName={setName}
          phone={phone}
          setPhone={setPhone}
          email={email}
          setEmail={setEmail}
          btnDisabled={btnDisabled}
          handlerOfSubmitForm={handlerOfSubmitForm}
          isRequestSuccess={isRequestSuccess}
          setIsRequestSuccess={setIsRequestSuccess}
          openModalWindow={openModalWindow}
          setOpenModalWindow={setOpenModalWindow}
        />
        <SectionTwoCt />
        <SectionThreeCt />
        <SectionFourCt />
        <PartnersSectionCt />
        <SectionFiveCt
          phone={phone}
          setPhone={setPhone}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          btnDisabled={btnDisabled}
          handlerOfSubmitForm={handlerOfSubmitForm}
          isRequestSuccess={isRequestSuccess}
          setOpenModalWindow={setOpenModalWindow}
        />
      </Layout>
    </>
  );
};

export default BusinessPage;
